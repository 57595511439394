import React, { useState, useEffect } from "react";
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Add_vehicle from "../Pages/Add_vehicle/Add_vehicle";
import Logo from "../images/logo.svg";
import Call_ic from "../images/call_ic.svg";
import Meassge_ic from "../images/message_ic.svg";
import User_ic from "../images/user_ic.svg";
import get_user_token from "../get_token";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Header = () => {
  // Humburger Menu
  // const [token, setToken] = useState("");

  const [menu_trigger, setclass] = useState(true);
  const notify = () => toast("Copied to clipBoard");
  const toggle_menu = () => {
    let html_block = document.querySelector("html");
    if (menu_trigger) {
      html_block.classList.add("open_menu");
      setclass(false);
    } else {
      html_block.classList.remove("open_menu");
      setclass(true);
    }
  };
  const loggedin = get_user_token();

  useEffect(() => {}, []);
  const logout = () => {
    localStorage.removeItem("token");
    Navigate("/Home");
  };
  // Check token and hide/show elements

  return (
    <header>
      <ToastContainer autoClose={700} />
      <div className="container">
        <div className="header_wrapper">
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="image" />
            </Link>
          </div>
          <div className="menu_block">
            <div className="menus">
              <ul>
                <li>
                  <NavLink onClick={toggle_menu} to="/">
                    Home
                  </NavLink>
                </li>
                <li>
                  <a
                    onClick={toggle_menu}
                    href="https://www.streamz.store/motobase"
                    target="_blank"
                  >
                    Shop
                  </a>
                </li>

                {/* <li>
                  <a href='/Home#benefits'>Benefits</a>
                </li>
                <li>
                  <a href='/Home#pricing'>Packages</a>
                </li>
                <li>
                <a href='/Home#about'>About</a>
                </li> */}

                {loggedin ? (
                  <>
                    <li>
                      <NavLink onClick={toggle_menu} to="/Add_vehicle">
                        Account
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={logout} to="/Home">
                        Logout{" "}
                      </NavLink>
                    </li>
                  </>
                ) : (
                  ""
                )}
              </ul>
            </div>

            <div className="social_icon_login_btn">
              {/* <div className="header_s_icon">
                <a href="#0">
                  <img
                    src={Call_ic}
                    alt="image"
                    onClick={() => {
                      navigator.clipboard.writeText("+44787654321");
                      toast("Copied ");
                    }}
                  />
                </a>
                <a href="#0">
                  <img
                    src={Meassge_ic}
                    alt="image"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        "enquiries@moto-base.co.uk"
                      );
                      toast("Copied");
                    }}
                  />
                </a>

                {loggedin ? (
                  <Link to="/Add_vehicle">
                    <img src={User_ic} alt="image" />
                  </Link>
                ) : (
                  ""
                )}
              </div> */}
              {/* {!loggedin ? ( */}
                {/* <div className="login_rigster_btn">
                  <NavLink
                    onClick={toggle_menu}
                    className="common_rl_btn register_btn"
                    to="/Home"
                  >
                    SignUp
                  </NavLink>
                  <NavLink
                    onClick={toggle_menu}
                    className="common_rl_btn"
                    to="/Home"
                  >
                    Login
                  </NavLink>
                </div> */}
              {/* ) : (
                ""
              )} */}
            </div>
          </div>
          <div className="humberger_and_icons">
            <div className="header_s_icon mobile_menus">
              {/* <a className="humberger_and_icons_hide" href="#0">
                <img src={Call_ic} alt="image" />
              </a> */}
              {/* <a className="humberger_and_icons_hide" href="#0">
                {" "}
                <img src={Meassge_ic} alt="image" />
              </a>{" "} */}
              {loggedin ? (
                <a href="#0">
                  <img src={User_ic} alt="image" />
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="menu_toggle_btn" onClick={toggle_menu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      {/* <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent> */}
    </header>
  );
};

export default Header;
