import React, { useState, useEffect } from "react";
import Header from "./Components/Header";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Add_vehicle from "./Pages/Add_vehicle/Add_vehicle";
import Footer from "./Components/Footer/Footer";
import Login from "./Pages/Login/Login";
import Change_password from "./Pages/Change_password/Change_password";
import Signup from "./Pages/Signup/Signup";
import CookieConsent, { Cookies } from "react-cookie-consent";
import CookiePolicy from "./Pages/CookiePolicy/CookiePolicy";
import PrivacyPolicy from "./Pages/Agreements/PrivacyPolicy";
import TermsAndCond from "./Pages/Agreements/TermsAndCond";
import PaymentConfirmed from "./Pages/PaymentConfirmed/PaymentConfirmed";
import PageNotFound from "./Pages/PageNotFound";
import ForgotPassword from "./Components/forgetPassword/ForgotPassword";
// import ReactGA from 'react-ga';

const TRACKING_ID = "G-MX7F1759YT";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   ReactGA.initialize(TRACKING_ID);
  //   ReactGA.pageview(location.pathname + location.search);
  // }, []);
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/Home" element={<Home />} />
        {/* <Route exact path="/Add_vehicle" element={<Add_vehicle />} /> */}
        {/* <Route exact path="/Signup" element={<Home />} /> */}
        {/* <Route exact path="/Login" element={<Home />} /> */}
        <Route exact path="/CookiePolicy" element={<CookiePolicy />} />
        {/* <Route exact path="/Change_password" element={<Home />} /> */}
        <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route exact path="/TermsAndCond" element={<TermsAndCond />} />
        {/* <Route exact path="/PaymentConfirmed" element={<PaymentConfirmed />} />
        <Route exact path="/forgotpassword" element={<Home />} />{" "} */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
      <CookieConsent
        buttonText="Accept"
        declineButtonText="view cookie policy"
        enableDeclineButton
        flipButtons
        declineButtonStyle={{ background: "#612881", color: "white" }}
        buttonStyle={{ background: "#612881", color: "white" }}
        onDecline={() => {
          navigate("/CookiePolicy");
        }}
      >
        <h1>Cookies</h1>
        By clicking “Accept All Cookies”, you agree to the storing of cookies on
        your device to enhance site navigation, analyse site usage, and assist
        in our marketing efforts. Preferences can be adjusted in Cookie
        Settings. View our
      </CookieConsent>
    </>
  );
};
export default App;
