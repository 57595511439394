import React, { useState, useEffect } from "react";
import Edit_ic from "../images/cross.png";
import get_user_token from "../get_token";
import { useNavigate } from "react-router-dom";

const Banner = (props) => {
  // Check token and hide/show elements
  const [token, setToken] = useState("");
  const [stolen, setStolen] = useState(false);
  const [PoliceRef, setPoliceRef] = useState("");
  const [checkResponse, setCheckResponse] = useState(false);
  const [ChasisNumber, setChasisNumber] = useState("");
  const [EngineNumber, setEngineNumber] = useState("");
  const [Make, setMake] = useState("");
  const [Model, setModel] = useState("");
  const [YOM, setYOM] = useState("");
  const [searchArea, setSearchArea] = useState("");
  const [bike_search_input, setBike_search_input] = useState("");
  const navigate = useNavigate();
  const setheader = "Token " + token;
  const loggedin = get_user_token();
  const websiteURL = process.env.REACT_APP_API_URL;

  //set token
  // useEffect(async () => {
  //   const token = await get_user_token();
  //   setToken(token);
  // }, []);
  // scroll to  result  tab
  const scrollToResult = () => {
    window.scrollTo({
      top: 550,
      behavior: "auto",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  //Search bike fn
  function bike_check_fn(event) {
    event.preventDefault();

    // //Get User Data
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      // headers : {
      //     "Content-Type" : "application/json",
      //     "Accept" : "application/json",
      //     "Authorization" : setheader
      // }
    };
    scrollToResult();
    const url = "https://api.moto-base.co.uk/rs/bike/v1/bikes?q=";
    fetch(`${url}${bike_search_input}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var get_vehicle_info = JSON.parse(result);
        var check_response = get_vehicle_info;
        // if (get_vehicle_info.detail == "Invalid token.") {
        //   document.getElementById("error_invalid").innerHTML =
        //     'Invalid token, Please login <a href="/Login">here</a>';
        //   document.getElementById("error_invalid").style.display = "block";
        // } else
        if (check_response.length >= 1) {
          setCheckResponse(false);
          setChasisNumber(get_vehicle_info[0].chassisNumber);
          setEngineNumber(get_vehicle_info[0].engineNumber);
          setMake(get_vehicle_info[0].make);
          setModel(get_vehicle_info[0].model);
          setYOM(get_vehicle_info[0].yearOfManufacture);

          document.getElementById("error_invalid").style.display = "none";
          setSearchArea(get_vehicle_info[0].area);
          // setPoliceRef(get_vehicle_info.temp_police_ref);
          setStolen(get_vehicle_info[0].stolen);
          document.getElementsByClassName(
            "returned_vehicle_section"
          )[0].style.display = "block";
        } else if (result.status === 404) {
          setCheckResponse(true);
        } else {
          setCheckResponse(true);
          document.getElementsByClassName(
            "returned_vehicle_section"
          )[0].style.display = "block";
        }
      })
      .catch((error) => console.log("error", error));
  }

  //Hide vehicle tile
  function close_tile() {
    let re_vehicle = document.querySelector(".returned_vehicle_box");
    re_vehicle.style.display = "none";
  }

  return (
    <>
      <div className="home_banner_section">
        <div className="container">
          <div className="home_banner_content">
            <div className="home_label_text">
              <p>Search</p>
            </div>
            <div className="home_banner_title">
              <h1>Chassis Number Check</h1>
            </div>
            <div className="common_desc">
              <p>
                Enter the Chassis Number of the bike that you would like to
                check. Unsure where to find the Chassis Number? The Chassis
                Number will be located on the neck of the bike frame on the
                right handside.
              </p>
            </div>
            <div className="home_banner_search_bar">
              <input
                onChange={(e) => setBike_search_input(e.target.value)}
                name="bike_search_input"
                type="text"
                placeholder="E.G. SS2000399"
              />
              <a href="#Returnedvehicle">
                <button onClick={bike_check_fn}>Search</button>
              </a>
              <p id="error_invalid">Invalid Chassis Number</p>
            </div>
            <div className="common_desc">
              <p></p>
            </div>
            <div className="common_desc">
              <p>
                Explore the Motobase experience with our latest app! Enjoy
                exclusive features, faster interactions, and instant updates.
                Download now for ultimate convenience—just tap the App Store
                badges! And with our app, easily book off road racing events and
                register your bike ownership. Download today!
              </p>
            </div>
            <div className="store_buttons">
              <div className="store_buttons_google">
                <a href="https://play.google.com/store/apps/details?id=za.drt.motobase.prod&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <img
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    border-radius="13"
                    width="280"
                    height="83"
                  />
                </a>
              </div>
              <div className="store_buttons_apple">
                <a href="https://apps.apple.com/gb/app/motobase/id6449926294?itsct=apps_box_badge&amp;itscg=30200">
                  <img
                    alt="Download on the App Store"
                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1686182400"
                    border-radius="13"
                    width="280"
                    height="60"
                  />
                </a>
              </div>
            </div>
            {/* {!loggedin ? (
              <div className="btnDiv">
                <button
                  className="common_btn"
                  onClick={() => {
                    navigate("/Home");
                  }}
                >
                  Register a Bike
                </button>
              </div>
            ) : (
              ""
            )} */}
          </div>
        </div>
      </div>

      <div className="returned_vehicle_section" id="Returned-vehicle-section">
        <div className="container">
          <div className="returned_vehicle_box">
            {/* <div className="rv_edit_btn" onClick={close_tile}>
              <a href="#0">
                <img src={Edit_ic} alt="ic" />
              </a>
            </div> */}
            <div className="common_section_title">
              <h2>Search Results</h2>
            </div>
            {checkResponse || typeof ChasisNumber === "undefined" ? (
              <p className="common_desc">
                No result, either the bike is not registered with Motobase or
                the customer who owns the bike does not have an active
                registration.
              </p>
            ) : (
              <div className="returned_v_info">
                <ul>
                  <li>
                    <p>
                      <b>Chassis Number : </b>{" "}
                      <span id="search-chasis">{ChasisNumber}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Engine Number : </b>{" "}
                      <span id="search-engine">{EngineNumber}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Registered Area : </b>{" "}
                      <span id="search-area">{searchArea}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Make : </b> <span id="search-make">{Make}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Model : </b> <span id="search-model">{Model}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Year of manufacture : </b>{" "}
                      <span id="search-yom">{YOM}</span>
                    </p>
                  </li>
                  <li>
                    <div>
                      <p>
                        {/* <b>Stolen: </b> <span id="search-stolen">Washington</span> --------------------*/}
                        {stolen ? (
                          <p className="common_desc">
                            <b>
                              This bike has been registered as stolen with
                              Motobase, please contact the police and contact
                              our email{" "}
                            </b>
                            <span>
                              <b>enquiries@moto-base.co.uk!</b>
                            </span>
                            {/* or <b>Call us </b> on{" "}
                            <span>0800123456</span> */}
                          </p>
                        ) : (
                          <b>
                            This bike has not been registered as stolen with
                            Motobase.
                          </b>
                        )}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      {stolen &&
                      PoliceRef &&
                      typeof PoliceRef !== "undefined" ? (
                        <p className="common_desc">
                          <b>Police Reference: </b> <p>{PoliceRef} </p>
                        </p>
                      ) : (
                        ""
                      )}{" "}
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div id="Returnedvehicle"></div>
    </>
  );
};

export default Banner;
