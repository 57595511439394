import img1 from '../../images/user1.png'
import img2 from '../../images/user2.png'
import img3 from '../../images/user3.png'

import card_search_image from '../../images/search_icon.svg'
import facebook from '../../images/facebook.svg'
import instagram from '../../images/instagram.svg'
import linkedin from '../../images/linkedin.svg'

const Card_data = [
    {
        card_image: img1,
        card_title: 'Dylan Evans',  
        card_detail : 'Co-founder, Head of Technical Development',
        facebook_icon : facebook,
        insta_icon : instagram,
        linkedin_icon : linkedin,
        search_icon : card_search_image
    },
    {
        card_image: img2,
        card_title: 'Jack Davies',  
        card_detail : 'Co-founder, Head of Marketing and Merchandise',
        facebook_icon : facebook,
        insta_icon : instagram,
        linkedin_icon : linkedin,
        search_icon : card_search_image
    },
    {
        card_image: img3,
        card_title: 'Jack Annetts',  
        card_detail : 'Co-founder, Logistical Lead',
        facebook_icon : facebook,
        insta_icon : instagram,
        linkedin_icon : linkedin,
        search_icon : card_search_image
    },
]
export default Card_data;