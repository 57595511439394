import React from 'react';

const TermsAndCond = () => {
  return (
    <>
        <div className='add_vehicle_page'>
            <div className="account_banner_section">
                <div className="container">
                    <div className="acc_inner_bg_content text-center">
                        <h1>Terms and Conditions</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className='cookieolicy_page'>
            <div className='container'>
                <label><b>Introduction</b></label>
                <br />
                Welcome to MOTOBASE, a site available at https://www.moto-base.co.uk, owned and managed by MOTOBASE LTD. Using MOTOBASE LTD, intended as a website (collectively, the "MOTOBASE LTD", the "Service", the "System") the User expressly agrees to have read, understood, and fully accepted our legal documentation consisting of Terms and Conditions, Privacy Policy, and Cookie Policy.
                <br />
                <br />
                We are the UK national online database for keeping your bikes safe. Our website will allow you to register ownership of your bike, change keeper and report stolen. Our team aims to tackle theft and resale of stolen bikes in the motocross community.
                <br />
                <br />
                If the User does not accept the content of one or more clauses expressed in our legal documentation, please do not use our services. In order to use the site, the User implicitly declares to have the faculty of accepting our legal documentation and to be in age. In the case of a minor, the User declares to operate under the supervision of an adult.                 
                <br />
                <br />
                <label><b>Changes to Agreements</b></label>
                <br/>
                Occasionally, we may make changes to the Service and/or our legal documentation at our discretion. When we make material changes to the legal documentation, we will notify users through the System. The continued use of MOTOBASE LTD after such changes involves an express acknowledgement and acceptance of the changes.
                <br />
                <br />
                <label><b>License and Copyright Policy</b></label>
                <br/>
                European Copyright Directive (alias EU 2019/790), MOTOBASE LTD Service and the content provided through it are owned by MOTOBASE LTD or its licensors. We grant users a limited, non-exclusive and revocable license for their personal and non-commercial use of the Service and to receive the multimedia content made available through our system in their country, based on the subscription or Trial selected (the "License ").
                <br />
                MOTOBASE LTD considers itself the owner of all the codes, graphics or computer contents present on the site. The user may not assign, transfer or sublicense the rights deriving from this License to third parties.
                <br />
                All MOTOBASE LTD trademarks, service marks, trade names, logos, domain names and all other features of MOTOBASE LTD brand are the exclusive property of MOTOBASE LTD This License does not grant you any right to use MOTOBASE LTD trademarks, service marks, trade names, logos, domain names, or other features of MOTOBASE LTD mark, whether for commercial or non-commercial use.
                <br/>
                <br/>
                The user agrees not to use MOTOBASE LTD Service (including but not limited to its content) in any way that is not expressly provided for in these Conditions. 
                <br />
                The third-party software collections included in MOTOBASE LTD Service are licensed according to the rules outlined in these Terms or the relevant license terms of such third parties.                <br />
                <br />
                <br />
                <label><b>Third Party Applications</b></label>
                <br/>
                MOTOBASE LTD, in order to properly provide its services, may (now or in the future) use third party applications, websites and content directly provided by third parties ("Third Party Applications"). The use of these applications is subject to their own conditions of use. You acknowledge and agree that MOTOBASE LTD is not responsible for any third-party application's behaviour, features, or content.
                <br />
                <br />
                <label><b>User Generated Content</b></label>
                <br/>
                MOTOBASE LTD users may (now or in the future) publish, upload and contribute ("post") Content on the Service, including text, messages, or reviews ("Content"). The user warrants that the Content does not violate the agreements in this document, applicable law, intellectual property or the rights of others. MOTOBASE LTD does not monitor, review, or modify the published Content but reserves the right to remove or disable access for any reason, including, but not limited to, Content that violates these Agreements in our sole discretion. MOTOBASE LTD can take these actions without prior notification to the user. The removal or disabling of access to the Content in question will be our sole discretion. We cannot guarantee the removal or disable of any Content that violates these Agreements.
                <br />
                MOTOBASE LTD is not responsible for the Content posted and does not endorse any opinion contained therein. If you believe that any Content violates intellectual property rights, please contact us using the e-mail address <mark>enquiries@moto-base.co.uk.</mark>
                <br />
                <br/>
                <label><b>Site and Service guidelines</b></label>
                <br/>
                MOTOBASE LTD respects intellectual property rights and expects users to do the same. MOTOBASE LTD has established some ground rules that users must follow when using the Service to ensure that MOTOBASE LTD remains a product that all can use. Please follow these rules and encourage other users to do the same.                
                <br />
                <br />
                <br/>
                The following is not permitted for any reason:                                
                <br />
                <ul>
                    <li> - copy, redistribute, reproduce, "extract", record, transfer, perform or publicly display, transmit or make available to the public any part of the MOTOBASE LTD Service or the Content, or make any use of the MOTOBASE LTD Service or the Content that is not expressly permitted under the Agreements or applicable law, or otherwise infringes the intellectual property rights (such as copyright) of MOTOBASE LTD Service, Content or any part thereof;</li>
                    <li> - use MOTOBASE LTD Service to import or copy local files for which you do not have the necessary legal rights to do so in this way;</li>
                    <li> - transfer copies of cached Content from an Authorized Device to any other Device by any means;</li>
                    <li> - perform reverse engineering, decompile, disassemble, modify or create derivative works based on MOTOBASE LTD Service, Content or any part thereof, except where permitted by applicable law;</li>
                    <li> - circumvent any MOTOBASE LTD material or copyright used by MOTOBASE LTD, its licensors or other third parties in order to protect the Content or Service;</li>
                    <li> - sell, rent, subcontract or lease any part of MOTOBASE LTD Service or the Content;</li>
                    <li> - circumvent any territorial restrictions applied by MOTOBASE LTD or its licensors;</li>
                    <li> - remove or modify copyright, registered trademarks or other indications of intellectual property contained in or provided through the MOTOBASE LTD Service (including in order to hide or alter any indications of ownership or origin of any Content);</li>
                    <li> - perform the "crawling" within MOTOBASE LTD Service, or use other automated means (including bots, scrapers and spiders) to collect information from MOTOBASE LTD.</li>
                </ul>
                <br/>
                In addition, the users expressly understood and agree that:
                <ul>
                    {/* <li> - Stolen recovery is only included in our Standard and Gold packages.</li> */}
                    {/* <li> - If users buy packages that do not include the stolen recovery then we offer a fee of £250 for MOTOBASE collection. You cannot claim for collection and then try to upgrade the package to benefit from the stolen recovery package.</li> */}
                    <li> - If the user does not keep registrations active(or renew), the bikes connected to the users account may not be displayed in our vehicle verification functions(Bike checker). This verification is the primary security feature to deter thieves from selling the bike.</li>
                </ul>
                <br />                
                <br/>
                <label><b>Limitations and Variations of the Service</b></label>
                <br/>
                MOTOBASE LTD will make reasonable efforts to keep MOTOBASE LTD Service operational. However, some technical difficulties or maintenance operations can cause temporary interruptions from time to time. Within limits established by the law in force, MOTOBASE LTD reserves the right to modify or interrupt, periodically and at any time, and temporarily or permanently, functions of MOTOBASE LTD Service, with or without notice and without any liability to users, except where prohibited by law, for any interruptions, changes or terminations of MOTOBASE LTD Service or other related functions or functionality. In any case, MOTOBASE LTD is not responsible for any potential failure to view and receive emails due to service malfunctions or during maintenance periods.                
                <br />
                <br/>
                <br/>
                <label><b>Payments, Refund, and Returns</b></label>
                <br/>
                MOTOBASE LTD accepts credit/debit cards and Stripe as valid payment’s methods. Using a specific method, the user expressly declares to be the owner or to have full rights of use.<br/>
                <br/>
                You will have 14 days to cancel for free but will be charged an administration fee of £ 15. In the case products, the user can send us an email and request the replacement completely free of charge for any items sent with our products.
                <br/>
                The user has the opportunity to contact us using our email address specifying the reason for the request and the products involved in the return/refund process. It will be our responsibility to provide instructions for accessing the requested return/refund. The user understands and accepts that the return shipping cost is at his own expense and does not apply to MOTOBASE LTD.
                <br/>
                <br/>
                <label><b>Warranty and Disclaimer</b></label>
                <br/>
                MOTOBASE LTD TRIES TO PROVIDE THE BEST SERVICE POSSIBLE. STILL, THE USER UNDERSTANDS AND AGREES THAT MOTOBASE LTD SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY EXPRESS OR IMPLIED WARRANTY OR CONDITION OF ANY KIND. THE USER USES MOTOBASE LTD SERVICE AT YOUR OWN RISK. TO THE EXTENT PERMITTED BY APPLICABLE LAW, MOTOBASE LTD AND ALL CONTENT OWNERS MAKE NO WARRANTY AND DISCLAIM ANY WARRANTY OR CONDITION OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE. MOTOBASE LTD AND THE CONTENT OWNERS DO NOT WARRANT IN ANY WAY THAT MOTOBASE LTD SERVICE IS FREE OF MALWARE OR OTHER HARMFUL COMPONENTS. FURTHERMORE, MOTOBASE LTD MAKES NO INSURANCE AND DOES NOT WARRANT, ENDORSEMENT OR ASSUME LIABILITY FOR ANY THIRD-PARTY APPLICATION (OR RELATED CONTENT), USER CONTENT OR OTHER PRODUCTS OR SERVICES PUBLISHED OR OFFERED BY THIRD PARTIES OR THIRD PARTIES.
                <br/>
                <br/>
                <br/>
                <label><b>Limitations</b></label>
                <br/>
                THE USER AGREES THAT, TO THE EXTENT PROVIDED BY THE LAW IN FORCE, THE ONLY AND SOLE REMEDY FOR ANY PROBLEMS OR DISSATISFACTIONS WITH THE MOTOBASE LTD SERVICE IS TO FINISH THE USE OF THE SERVICE. EVEN IF MOTOBASE LTD ASSUMES NO LIABILITY FOR THIRD-PARTY APPLICATIONS OR THEIR CONTENT, AND EVEN IF THE RELATIONSHIP WITH SUCH THIRD-PARTY APPLICATIONS MAY BE GOVERNED BY SEPARATE AGREEMENTS WITH SUCH THIRD PARTIES, WITHIN THE LIMITS PROVIDED FOR BY LAW. OFFERED BY MOTOBASE LTD FOR ANY PROBLEMS OR DISSATISFACTIONS WITH THIRD-PARTY APPLICATIONS OR THEIR CONTENT CONSISTS OF UNINSTALLING AND / OR STOPPING USING SUCH THIRD-PARTY APPLICATIONS.
                <br/>
                <br/>
                TO THE MAXIMUM LIMITS PROVIDED BY LAW, IN NO EVENT SHALL MOTOBASE LTD BE LIABLE FOR (1) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES; (2) ANY LOSS OF USE, DATA, BUSINESS OR GOODS (WHETHER DIRECT OR INDIRECT), IN ANY CASE ARISING FROM THE USE OR INABILITY TO USE THE MOTOBASE LTD SERVICE, THIRD-PARTY APPLICATIONS OR THE CONTENT OF THIRD-PARTY APPLICATIONS, REGARDLESS OF ANY LEGAL THEORY AND THE FACT THAT MOTOBASE LTD HAS BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES, AND EVEN WHERE A REMEDY DOES NOT OBTAIN THE EXPECTED RESULT; OR (3) ANY AGGREGATE LIABILITY FOR ANY CLAIMS RELATING TO MOTOBASE LTD SERVICE, THIRD-PARTY APPLICATIONS OR THIRD PARTY APPLICATION CONTENT OF VALUE IN EXCESS OF THE AMOUNTS YOU PAID TO MOTOBASE LTD DURING THE PREVIOUS TWELVE MONTHS IN THESE PERIODS. BY THE LAW IN FORCE.
                <br/>
                <br/>
                <label><b>Severability and waiver</b></label>
                <br/>
                Except as otherwise stated in the Agreements, where any provision of the Agreements is held to be invalid or unenforceable for any reason or to any level, such invalidity or inapplicability will not in any way interfere with or render invalid or unenforceable the remaining provisions of the Agreements, and the application of this clause will be imposed within the limits established by law.                
                <br/>
                <br/>
                Any failure to apply the Agreements or related clauses by MOTOBASE LTD or third-party beneficiaries will not cancel the right of MOTOBASE LTD or the third-party beneficiary to do so.            
                <br/>
                <br/>
                <label><b>Indemnification</b></label>
                <br/>
                Within the maximum limits provided for by the law in force, the user agrees to indemnify and hold MOTOBASE LTD harmless from and in relation to damages, losses and expenses of any kind (including reasonable legal costs and fees) resulting from:
                <br/>
                <br/>
                1) The violation of this Agreement by the user.
                <br/>
                2) Any User Content.
                <br/>
                3) Any activity in which the user engages or performs through MOTOBASE LTD Service.
                <br/>
                4) The breach of any law or the User's rights of a third party.                
                <br/>
                <br/>
                <label><b>Jurisdiction</b></label>
                <br/>
                MOTOBASE LTD operates in full compliance with the laws in force provided for by the UK legal systems. In this sense, any legal dispute or dispute will be resolved in the exclusive courts of the United Kingdom.                
                <br/>
                <br/>
                <label><b>Contacts</b></label>
                <br/>
                For any questions about MOTOBASE LTD Service or the Agreements, users can contact us via our email address <mark>enquiries@moto-base.co.uk.</mark>
                <br/>
                <br/>
            </div>
        </div>
    </>
  )
}

export default TermsAndCond;