import React from 'react';

const CookiePolicy = () => {
  return (
    <>
        <div className='add_vehicle_page'>
            <div className="account_banner_section">
                <div className="container">
                    <div className="acc_inner_bg_content text-center">
                        <h1>Cookie Policy</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className='cookieolicy_page'>
            <div className='container'>
                <label><b>GDPR COOKIE POLICY</b></label>
                <br />
                According to the Data Protection Act (GDPR) and Data Protection Act 2018 (DPA), in order to make navigation on the site as easy as possible, the Data Controller uses portions of code installed in the browser, which assist him in providing the service according to the purposes described ("cookies"). This site can use the following types of cookies:
                <br />
                <br />
                <label><b>A. Essential technical cookies</b></label>
                <br/>
                These Cookies are necessary for the correct functioning of the Site. They allow the navigation of the pages, storage access credentials for faster access to the Site, keeping preferences and credentials active during navigation, and saving individual user sessions. Essential technical cookies allow us to provide the service.
                <br />
                <br />
                <label><b>B. Statistical and performance cookies</b></label>
                <br/>
                These cookies allow you to know how users use the site to evaluate and improve its functioning and better respond to user needs. We can track which pages are the most and most minor frequented through statistical and performance cookies, the number of visitors, the time spent on the site by the average user, and how they arrive. All information collected by these cookies is anonymous and not linked to the user's data.
                <br />
                <br />
                <label><b>C. Third-Party Cookies</b></label>
                <br/>
                This type of service allows interaction with additional features on the Site or other components based on external platforms. The information acquired by this Site through the user's interactions with these cookies is in any case subject to the settings relating to the management and protection of personal data of the single platform with which the Site interacts. We only use the technical cookies set up by Google Analytics to date.
                <br />
                <br />
                <label><b>User rights, denial and cancellation of the use of cookies</b></label>
                <br/>
                At any time, without the consent of third parties, the User can manage preferences relating to Cookies directly within their web browser and prevent or limit the use of this technology used by second and third parties.                
                <br />
                <br/>
                Through the exact preferences of the web browser, the User can delete cookies already installed in the past, denying access to sensitive data. In this sense, the user could receive a limited service or even not receive the provision of services at all.
                <br />
                <br/>
                In the case of services provided by third parties, the User can also exercise his right to oppose the tracking by inquiring through the third party's privacy policy, expressed in our privacy policy. Independently from the provided information, the Data Controller informs the user about the use of Your Online Choices. Through this service, it is possible to manage the tracking preferences of most advertising tools.
                <br />
                <br />
                <br />
                <br />
            </div>
        </div>
    </>
  )
}

export default CookiePolicy;