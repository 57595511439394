import React, { useState } from "react";
import Banner from "../../Components/Banner";
import Edit_ic from "../../images/cross.png";
import Ob_img1 from "../../images/bike_still.jpeg";
import Ob_img2 from "../../images/girl_bike.jpeg";
import Gold_img_bg from "../../images/IMG-3099.avif";
import Card_data from "../../Components/Card_block/Card_data";
import Card from "../../Components/Card_block/Card";
import bike from "./images/bike.png";
import Register from "../../Components/Register/Register";
import get_user_token from "../../get_token";
import { Link, useNavigate } from "react-router-dom";
import image1 from "./images/image 35-1.png";
import image2 from "./images/image 36.png";
import image3 from "./images/apppagesmerge.png";
import smartwaterlogo from "./images/smartwaterlogo.png";
import logo1 from "./images/logo1.png";
import logo2 from "./images/logo2.png";
// import logo3 from "./images/logo3.jpg";
import "./Home.css";
import Iframe from "react-iframe";
import ReactPlayer from "react-player";
const Home = (props) => {
  const [card, setcard] = useState(Card_data);
  const loggedin = get_user_token();
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  return (
    <div className="main_content_block home_page">
      <Banner banner_title="Home" />
      {/* video section */}
      {/* <div className="videoSectionContainer">
        <div className="common_section_title text-center">
          <h2>How to get started </h2>
        </div>

        <div className="videoContainer">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=u6N8Xte52sI"
            playing={false}
            width="100%"
            height="100%"
          />
        </div>
      </div> */}
      <div className="statistics_on_bike_section">
        <div className="container">
          <div className="statistics_on_bike_block">
            <ul>
              <li>
                <div className="st_on_bike_info">
                  <h4>72,000</h4>
                  <p>Bikes are stolen each year across the UK.</p>
                </div>
              </li>
              <li>
                <div className="st_on_bike_info">
                  <h4>£3 million</h4>
                  <p>
                    Is made every month by thieves selling stolen dirt bikes.
                  </p>
                </div>
              </li>
              <li>
                <div className="st_on_bike_info">
                  <h4>80%</h4>
                  <p>
                    Of stolen dirt bikes are taken from the owners' address.
                  </p>
                </div>
              </li>
              <li>
                <div className="st_on_bike_info">
                  <h4>58,000</h4>
                  <p>
                    Motocross riders in the UK are all at risk of bike theft.{" "}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Benefits section  */}

      <div className="Benefits-section-container" id="benefits">
        <div className="benefitsHeader">
          <h2>Benefits of Motobase </h2>{" "}
        </div>

        <div className="benefitsDiv div1">
          <div className="border1"></div>
          <div className="benefitsText">
            <h4>Registering your bike</h4>
            <p>
              When you register ownership of your bike(s) with Motobase, it
              enables traders and prospective buyers to verify the
              authenticity/legitimacy of the bike before making a purchase.
              Transferring ownership to a new keeper is a straightforward
              process, requiring only the bike's chassis number and the new
              owner's email address. You have the flexibility to register
              various off-road bikes, including motocross, enduro, trials and
              trail bikes, all under a single account.
            </p>
          </div>
          <div className="benefitsImage">
            <img src={image1} alt="" />
          </div>
        </div>
        <div className="div2">
          <div className="border2"></div>

          <div className="benefitsImage2">
            <img className="divImg2" src={image2} alt="" />
          </div>
          <div className="benefitsText2">
            <h4>Benefits for traders</h4>
            <p>
              We provide customised packages for traders, dealers and businesses
              in the motocross and enduro community. These packages enable
              traders to verify the authenticity/legitimacy of bikes through our
              'Chassis Number Check' before making a purchase. As a partnered
              business, you can conveniently register all your bikes under one
              account, giving your customers peace of mind by ensuring the
              traceability of their new bike.
            </p>
          </div>
        </div>
        <div className="benefitsDiv div3">
          <div className="border1"></div>

          <div className="benefitsText">
            <h4>Booking Events</h4>
            <p>
              Motobase's intuitive booking system simplifies motocross event
              access. Our user-friendly mobile app, available on Apple Store and
              Google Play Store, provides one-stop access. Users can browse
              diverse motocross events, register, and choose from various
              skill-based classes. Our system fosters community engagement
              through vendor connections and media coverage. Discover, book, and
              immerse yourself in the world of motocross with Motobase.
            </p>
          </div>
          <div className="benefitsImage">
            <img src={image3} alt="" />
          </div>
        </div>
        {/* <div className="btnDiv">
          <button
            className="common_btn"
            onClick={() => {
              navigate("/Home");
            }}
          >
            Sign Up
          </button>
        </div> */}
      </div>
      <div className="pricing_paln_section" id="pricing">
        <div className="pricing_section">
          <div className="container">
            <div className="pricing_content">
              <div className="home_label_text">
                <p>Packages</p>
              </div>
              <div className="common_section_title text-center">
                <h2>Join today, download the app.</h2>
              </div>
              <div className="common_desc">
                <p>
                  Get ready for the ride of your life with Motobase! Our premium
                  subscription is launching soon, offering exclusive features
                  and benefits. Stay tuned!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="pricing_plan_wrap">
          <div className="container">
            <div className="pricing_paln_block">
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="common_price_box">
                    <p className="popular_label">Limited</p>
                    <div className="price_name_label">
                      <p>One</p>
                    </div>
                    <div className="price_amount">
                      <h3>
                        Free <span></span>
                      </h3>
                    </div>
                    <div className="price_desc">
                      <p>Tier One - Limited Time Only</p>
                    </div>
                    <div className="price_select_value">
                      <ul>
                        <li>Free bike registrations</li>
                        <li>Limited Free Chassis Number Searches</li>
                      </ul>
                    </div>
                    {/* <div className="section_btn">
                      {!loggedin ? (
                        <Link
                          to="/Home"
                          onClick={scrollToTop}
                          className="common_btn"
                        >
                          Get started
                        </Link>
                      ) : (
                        <Link
                          to="/Add_vehicle"
                          onClick={scrollToTop}
                          className="common_btn"
                        >
                          My account{" "}
                        </Link>
                      )}
                    </div> */}
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="common_price_box gold_price_box">
                    <p className="popular_label">Coming Soon!</p>
                    <div className="price_name_label">
                      <p>Two</p>
                    </div>
                    <div className="price_amount">
                      <h3>Premium Membership</h3>
                    </div>
                    <div className="price_desc">
                      <p>Tier 2 - Annual registration</p>
                    </div>
                    <div className="price_select_value">
                      <ul>
                        <li>Smartwater pack</li>
                        <li>Free Ride days</li>
                        <li>Discounts on top brands</li>
                        <li>Entered into monthly competitions</li>
                      </ul>
                    </div>
                    {/* <div className="section_btn">
                      {!loggedin ? (
                        <Link
                          to="/Home"
                          onClick={scrollToTop}
                          className="common_btn"
                        >
                          Get started
                        </Link>
                      ) : (
                        <Link
                          to="/Add_vehicle"
                          onClick={scrollToTop}
                          className="common_btn"
                        >
                          My account{" "}
                        </Link>
                      )}
                    </div> */}
                    <div className="gold_img_bg">
                      {/* <img src={Gold_img_bg} alt="gold motobase package" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* about section */}
      <div id="about" className="about_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 ">
              <div className="about_info_section aboutText">
                <h3 className="common_heading">About Motobase</h3>
                <p className="paragraph">
                  We are Motobase, the National Dirtbike Database. Our platform
                  enables users to register bike ownership, facilitate ownership
                  transfers and report stolen dirtbikes. Our primary goal is to
                  combat theft and the illicit resale of stolen dirt bikes
                  within the motocross community.
                </p>
                <p className="paragraph pt-3">
                  Historically, UK police forces seize stolen dirt bikes, but
                  have lacked a means to determine from where the bike was
                  stolen. The MOTOBASE team now bridges this gap by assisting
                  the police in reuniting stolen bikes with their rightful
                  owners.
                </p>
                {/* <p className='paragraph pt-3'>
                  Thieves will often try to sell on stolen dirt bikes in the
                  quickest and easiest way, this can often be through classified
                  ad sites or through second hand bike stores. This is why we
                  aim to work closely with all police forces around the UK to
                  ensure all off road dirt bikes have registered keepers and
                  stolen dirt bikes are returned to their rightful owners.
                </p> */}
                {/* <h4 className="partnershipsText">Partnerships</h4> */}
              </div>
              <div className="logos">
                {/* <img src={smartwaterlogo} />
                <img src={logo1} />
                {/* <img src={logo3} /> 
                <img src={logo2} /> */}
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="about_bike_block aboutimgDiv">
                <img src={bike} alt="dirt bike" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* smart water section  */}
      <div className="Benefits-section-container smartwaterDiv">
        <div className="benefitsDiv div1 ">
          <div className="benefitsText smartwaterText">
            <h4>Smartwater Partnership</h4>
            <p>
              SmartWater® forensic marking technology is a revolutionary
              invisible fluid solution that assigns a distinct identity to all
              components of your dirtbike. SmartWater, often referred to as the
              'traceable liquid,' offers a legally indisputable means to deter
              theft, even if your bike is disassembled or its frame numbers are
              tampered with, you can still identify your parts through your
              unique SmartWater® code. Each SmartWater bottle contains an
              exclusive, unremovable formula that remains effective for a
              minimum of 5 years. This solution has undergone rigorous testing,
              enduring exposure to harsh solvents, cleaning agents and even
              explosions!
              <br></br>
              <br></br>
              SmartTrace® and SmartSpray® products have a track record of over
              20 years, proving their effectiveness in both domestic and
              commercial settings. To benefit from this technology, simply
              purchase your SmartWater, apply it to your entire bike, register
              your unique bottle with SmartWater and remember to transfer the
              registration when selling your bike! Register{" "}
              <span>
                <a
                  href="https://www.smartwater.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Here
                </a>
              </span>
            </p>
          </div>
          <div className="benefitsImage smartwaterLogo">
            {/* <img src={smartwaterlogo} alt='' /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
