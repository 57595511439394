import React from 'react';

const PrivacyPolicy = () => {
  return (
    <>
        <div className='add_vehicle_page'>
            <div className="account_banner_section">
                <div className="container">
                    <div className="acc_inner_bg_content text-center">
                        <h1>GDPR Privacy Policy</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className='cookieolicy_page'>
            <div className='container'>
                <label><b>Introductory Considerations</b></label>
                <br />
                On 25 May 2018, the new General Data Protection Regulation (GDPR - General Data Protection Regulation) came into force. The objective of the European Union is to provide further protection to personal data, defined as "any information concerning an identified or identifiable natural person (so-called Data Subject)". This Privacy Policy provides users of this site with clear and detailed information on the processing of their data according to the General Data Protection Regulation (for potential EU-based users), Data Protection Act 2018 (the acting privacy regulation for the United Kingdom), and Personal Data Protection Code. In particular, by consulting this document, the reader will have the possibility to confer the set of data collected, the methods of their use, and the rights at his disposal regarding the protection of sensitive data.
                <br />
                <br />
                <label><b>Data Controller (Alias, Data Controller)</b></label>
                <br/>
                According to EU regulation 2016/679, aka <b>General Data Protection Regulation</b>, and Data Protection Act (<b>DPA</b>), for the <b>MOTOBASE LTD</b> site available at the URL <a href="https://www.moto-base.co.uk">https://www.moto-base.co.uk</a>, <b>MOTOBASE LTD</b> is the Data Controller for the processing of personal data collected on users of the site itself. For any information or notification regarding privacy, please refer to the company email <mark>enquiries@moto-base.co.uk.</mark><br />
                <br />
                <label><b>Data collected through the website</b></label>
                <br/>
                The Personal Data object of MOTOBASE LTD's activity is related to the following categories: personal data about the user, email address including features and email providers in case of newsletter’s form subscription, metric data on the devices used (such as model, brand, operating system and a unique identifier for sending push notifications.)
                <br />
                <br />
                <label><b>Third-Party Plugins/Resources</b></label>
                <br/>
                <ul>
                    <li> - Stripe: to process payments and manages website transactions. Users can read Stripe's Privacy Policy <a href="https://stripe.com/it/privacy">here.</a></li>
                    <li> - Google Analytics: to acquire, process, and manage monitoring data and statistics about the usage of our website. Users can read the Google’s Privacy Policy<a href="https://policies.google.com/privacy?hl=en-US"> here.</a></li>
                </ul>
                <br />
                <br />
                <label><b>Purposes and methods of processing</b></label>
                <br/>
                MOTOBASE LTD will process the Personal Data for the exclusive purpose of carrying out the typical business operations. They include improving/providing the services, covering the tax purposes provided for by the law where the company is based, and for marketing/contact purposes. MOTOBASE LTD will operate according to this agreement, within limits and according to the procedures set out in the Privacy Law.
                <br />
                It, therefore, undertakes to:
                <br/>
                1. a) Treat Personal Data lawfully, adopting all organizational measures proper to minimize the processing of only those Personal Data strictly necessary to execute Contracts or legal obligations correctly;
                <br />
                2. b) Process Personal Data following the information on the methods of processing Personal Data provided to Data Subjects through the information on the processing of Personal Data;
                <br />
                3. c) Verify that the Personal Data are accurate, relevant, complete and not exceeding the purposes for which they were processed. It includes the reporting in writing to third parties any needs to modify, update, correct or delete Personal Data and undertake to update them, modify, correct or delete them at their request.
                The treatment will be carried out using IT and telematic tools. Such data will not be communicated or accessible to third parties except in the cases provided for by law. 
                <br />
                <br />
                <label><b>Transfer of Personal Information</b></label>
                <br/>
                Regarding the transfer of data to third parties, MOTOBASE LTD undertakes to follow and correctly apply the instructions dictated by the legislation, refraining from defining any methods or purposes of processing other than those imposed by the agreements with its customers. In other words, in complete agreement with the current legislation on data protection (EU 2016/679, art.13/14), any potential transfer to third parties will be requested from time to time by the user concerned, who may object and deny your consent except in the following cases:
                <ul>
                    <li> - Transfer upon request for legal proceedings, legal acts, judicial and/or police investigations.</li>
                    <li> - Transfer for documents and information required by the police.</li>
                    <li> - Transfer to our partner for the correct provision of the service offered.</li>
                </ul>
                <br />
                <br />
                <label><b>Data Retention</b></label>
                <br/>
                MOTOBASE LTD collects customer data to provide its services and retains this information for the duration of the contracts or at the latest until the end of the year of operations to fulfil tax purposes. Furthermore, MOTOBASE LTD retains the data for a period after the termination of contracts only and only in relation to regulatory, tax, insurance and other requirements in the places it operates. Once this information is no longer necessary for the provision of services, we take measures to prevent access or use of this information for purposes other than security, fraud prevention and detection. In any case, we do not take legal responsibility for any unwanted access or any hacking activity by third parties.
                <br />
                The data is stored exclusively in electronic and non-paper formats.
                <br/>
                <br/>
                <label><b>User rights in accordance with the GDPR and DPA</b></label>
                <br/>
                The end-user has the right, following current legislation, to exercise one or more options from those described below:
                                <br />
                <ul>
                    <li><b> - Right of withdrawal:</b> the end-user has the full right to request the complete termination of the consent of the data collected by us at any time and without giving a reason.</li>
                    <li><b> - Right to be forgotten:</b> the end-user has the full right to request the complete cancellation, from any media, of their data collected by us, without providing any express reason. By exercising this action, he understands that the provision of our services may be subject to limitations or may be completely denied.</li>
                    <li><b> - Right of rectification and access to data:</b> the end-user has the full right to check at any time, the set of data that the company has collected on it. Additionally, he can request the rectification and modification of the data collected (for example, following a change of domain or method of telephone/e-mail contact).</li>
                    <li><b> - Right to request support from the Irish Privacy Guarantor:</b> the end-user has the right to request permission and additional information from the Privacy Guarantor, available at <a href="https://www.privacyshield.gov/welcome">https://www.privacyshield.gov/welcome</a></li>
                </ul>
                <br />                
                <br/>
                <label><b>How to exercise your rights</b></label>
                <br/>
                When declaring the Data Controller, the user can exercise their rights set out above directly by contacting us using the e-mail address <mark>enquiries@moto-base.co.uk.</mark>
                <br />                
                <br/>
                <br />                
                <br/>
            </div>
        </div>
    </>
  )
}

export default PrivacyPolicy;