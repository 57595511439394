import React from 'react';
import { Link } from 'react-router-dom';
import '../Footer/Footer.css';
import footer_logo from '../../images/footer_logo.svg';
import facebook from '../../images/facebook.svg';
import instagram from '../../images/instagram.svg';
import linkedin from '../../images/linkedin.svg';
import twitter from '../../images/twitter.svg';
import footer_bike from '../../images/footer_bike.png';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  return (
    <footer>
      <div className='container'>
        <div className='row'>
          <div className='all_cols'>
            <div className='footer_col_one '>
              <div className='footer_logo'>
                <Link to='/'>
                  <img
                    src={footer_logo}
                    alt='logo'
                    onClick={() => scrollToTop()}
                  />
                </Link>
              </div>
              Enquiries@moto-base.co.uk
              {/* <ul className="footer_social">
                <li>
                  <a href="#0">
                    <img src={facebook} alt="social" />
                  </a>
                </li>
                <li>
                  <a href="#0">
                    <img src={instagram} alt="social" />
                  </a>
                </li>
                <li>
                  <a href="#0">
                    <img src={linkedin} alt="social" />
                  </a>
                </li>
                <li>
                  <a href="#0">
                    <img src={twitter} alt="social" />
                  </a>
                </li>
              </ul> */}
            </div>
            <div className='footer_col_two  common_col '>
              <h5 className='footer_title'>Quick Links</h5>
              <ul>
                <li>
                  <Link to='/' onClick={scrollToTop}>
                    {' '}
                    Home
                  </Link>
                </li>

                {/* <Link to="#about"> About us</Link> */}
                <li>
                  <a href='/Home#about'>About</a>
                </li>
                {/* <li>
                  <a href="#team">Team</a>
                </li> */}
              </ul>
            </div>
            <div className='footer_col_three common_col '>
              <ul>
                <li>
                  {/* <Link to="/" onClick={scrollToTop}>
                    {" "}
                    Contact us
                  </Link> */}
                </li>
                <li>
                  <Link to='/CookiePolicy' onClick={scrollToTop}>
                    {' '}
                    Cookie Policy
                  </Link>
                </li>
                <li>
                  <Link to='/TermsAndCond' onClick={scrollToTop}>
                    {' '}
                    Terms and Conditions
                  </Link>
                </li>
                <li>
                  <Link to='/PrivacyPolicy' onClick={scrollToTop}>
                    {' '}
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className='footer_col_four common_col '>
              <ul>
                {/* <li>
                                    <Link to="/"> News   </Link>
                                </li>
                                <li>
                                    <Link to="/"> Events</Link>
                                </li> */}
                {/* <li>
                                    <Link to="/"> Advice</Link>
                                </li> */}
              </ul>
            </div>
          </div>
          <div className='copyright_block'>
            <p>
              Copyright © 2022 <Link to='/TermsAndCond'> MOTOBASE Ltd</Link>.
              All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
